export const FirebaseConfig = {
	"projectId": "openservicetrucking-67066",
	"appId": "1:688304692516:web:6155687b020f5d55d0e7b1",
	"databaseURL": "https://openservicetrucking-67066-default-rtdb.firebaseio.com",
	"storageBucket": "openservicetrucking-67066.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyApVoYCwq7WqqEIn-dpc7yfr2Rmxw31bfM",
	"authDomain": "openservicetrucking-67066.firebaseapp.com",
	"messagingSenderId": "688304692516",
	"measurementId": "G-EWQBPGKKHP"
};